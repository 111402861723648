:root .GameBox {
    border-radius: 15px;
    overflow: hidden;
    background-color: var(--section-bg);
}
.cover {
    width: 100%;
    height: 240px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 6px 6px 0 0;
}
.GameBox .logo {
    position: absolute;
    width: 90px;
    height: 90px;
    left: 22px;
    top: -45px;
    border-radius: 9px;
    overflow: hidden;
    border: 2px solid white;
}
.GameBox .logo img {
    width: 90px;
    height: 90px;
}
.GameBox .content {
    position: relative;
}
.GameBox .content .row .col.gameName {
    font-size: 14px;
    color: var(--text-dark);
    font-family: LufgaRegular;
}
.GameBox .content .row .col.buttonsWrapper {
    display: flex;
}

.GameBox .content .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: center;
    padding: 0px 25px;
    height: 30px;
    margin-bottom: 20px;
}

.GameBox .content .row .col > img {
    margin-right: 15px;
}

.GameBox .content .row .col {
    color: var(--text-light);
    font-family: LufgaLight;
}

.GameBox .content .row.spacer {
    height: 35px;
    margin: 0px;
}

.CopyButton {
    background-color: var(--body-bg);
    display: flex;
    padding: 0px 10px;
    align-items: center;
    border-radius: 10px;
    font-size: 12px;
    cursor: pointer;
    color: var(--text-light);
    height: 35px;
    margin-left: 10px;
}

.CopyButton .col.icon {
    margin-right: 5px;
}

.GameBoxSettings {
}
.GameBoxSettings .row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}
.GameBoxSettings .row .col {
}

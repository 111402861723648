.FormWrapper {
    /* background-color: var(--section-bg); */
    padding: 50px 60px;
    border-radius: 15px;
    margin: 0px 50px;
}

.formRow {
    display: flex;
    margin-bottom: 50px;
}
.formRow .formCol {
    width: 50%;
}

.Menu {
    background-color: var(--section-bg);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px;
    border-radius: 0 20px 20px 0;
}

.MenuWrapper {
    transition: all 0.1s ease-in;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.MenuWrapper.open {
    width: var(--menuOpenWidth);
}

.MenuWrapper.closed {
    width: 60px !important;
}

.LogoBox {
    height: 70px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navigation {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.navigation li a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 17px;
    border-radius: 15px;
    font-family: LufgaLight;
    font-size: 16px;
    color: var(--text-light);
}
.navigation li a.active,
.navigation li a:hover {
    background: var(--body-bg);
    color: black;
}

.navigation li a img {
    margin-right: 20px;
}

.navigation li .divider {
    height: 0px;
    width: 100%;
    border-top: 1px solid var(--menu-divider-color);
    margin: 15px 0px;
}

.navigation li .theme-switcher {
    justify-content: space-between;
    align-items: center;
}

.menuToggleButton {
    cursor: pointer;
}
.MenuWrapper.closed .menuToggleButton {
    width: 100%;
    text-align: center;
}

.subMenuIndicator {
    width: 30px !important;
    display: inline-flex;
    width: 100%;
    justify-content: flex-end;
}

.subMenuIndicator img {
    margin-right: 0px !important;
}

.navigation li a.subMenuItem {
    font-size: 14px;
}

.MenuWrapper.closed .subMenuIndicator,
.MenuWrapper.closed a.subMenuItem {
    display: none;
}

.menuWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
}

.LogoBox {
    padding: 20px;
    padding-bottom: 10px;
}

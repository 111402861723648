@import "fonts.css";

:root,
[data-color-theme="light"] {
    --body-bg: #fbfbfb;
    --pink: #f1406f;
    --border-light: #f3f3f3;
    --text-dark: #261c28;
    --text-light: #919297;
    --menu-bg: white;
    --menu-icon-color: #919297;
    --menu-divider-color: #e4e4e5;
    --section-bg: white;
}

[data-color-theme="dark"] {
    /* --body-bg: #2d2d2d; */
    --body-bg: #222222;
    --menu-bg: black;
    --border-light: #f3f3f3;
    --text-dark: #261c28;
    --text-light: #919297;
    --section-bg: #2d2d2d;
}

.ant-picker-input,
::placeholder,
.ant-picker-year-btn,
.ant-picker-month-btn {
    font-family: LufgaRegular !important;
}

.Login {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background-color: var(--body-bg);
}

.loginFormWarpper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100vh;
}
.loginFormWarpper .loginFormContainer {
    width: 400px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.loginFormWarpper .loginFormContainer .formContainer {
    /* background: red; */
}
.loginFormWarpper .logoContainer {
    margin-bottom: 20px;
}
.loginFormWarpper .titleContainer {
    font-size: 22px;
    margin-bottom: 40px;
    font-family: LufgaLight;
}

.login-form-forgot {
    float: right;
}

.loginLinks {
    height: 40px;
}

.loginLinks .linksContainer a {
    margin-right: 10px;
    margin-left: 10px;
    font-size: 12px;
}

.loginLinks .linksContainer {
    display: flex;
    justify-content: center;
}

.photos {
    /* border: 1px solid #f1406f; */
    margin-top: 2rem;
    border-radius: 20px;
    min-width: 60%;
    max-width: 90%;
    width: 390px;
    min-height: 30vh;
    padding: 2rem;
    display: flex;
    justify-content: center;
    gap: 2rem;
    /* background: rgb(0, 0, 0, 0.025); */

}

.wrapper-layout {
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
}

.list li {
    margin: 1rem 0;
}

.rules {

    align-items: center;
}

span.upload {
    position: relative;
}

.upload-button {
    position: absolute;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow-wrap: break-word;
    flex-direction: column;
    display: flex;
    gap: .75rem;
    background-color: #f1406f;
    border: none;
    color: white;
    font-weight: bold;
    ;

}

.upload-button span {

    font-size: 0.9rem;
    margin-inline-end: unset !important;
}

.upload-button span.ant-btn-icon {

    margin-inline-end: unset;
}

.upload-button span.ant-btn-icon span {
    font-size: 1.1rem;
}

.warning-avatar {
    margin: 1rem auto auto 1.25rem;
}
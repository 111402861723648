.Layout {
    display: flex;
    height: 100vh;
    background-color: var(--body-bg);
}

.layout__col__menu {
}

.layout__col__content {
    flex-grow: 1;
    height: 100vh;
    padding-bottom: 50px;
    overflow: hidden;
    overflow-y: auto;
}

@font-face {
    font-family: "LufgaLight";
    src: url("./../../public/assets/fonts/LufgaLight.ttf") format("truetype");
    font-weight: light;
    font-style: light;
}

@font-face {
    font-family: "LufgaRegular";
    src: url("./../../public/assets/fonts/LufgaRegular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "LufgaSemiBold";
    src: url("./../../public/assets/fonts/LufgaSemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: bold;
}

@font-face {
    font-family: "LufgaBold";
    src: url("./../../public/assets/fonts/LufgaBold.ttf") format("truetype");
    font-weight: bold;
    font-style: bolder;
}

@import "./theme.css";
@import "./fonts.css";
html,
body {
    padding: 0;
    margin: 0;
    font-family: LufgaRegular;
    font-size: 14px;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
    font-family: LufgaRegular;
}

img {
    max-width: 100%;
    height: auto;
}

h1,
h2,
p,
ul {
    margin: 0;
}

ul {
    padding: 0;
    list-style: none;
}

button {
    padding: 0.5rem 1rem;
    font-weight: bold;
}

:root {
    --menuOpenWidth: 10vw;
    --layout-content-part-width: calc((100vw - var(--menuOpenWidth) - 30px));
}

.mobileDetected {
    background: #f1406f;
    color: #fff;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding-top: 100px; */
    z-index: 999999999;
}

.mobileDetected h1 {
    text-align: center;
    font-size: 20px;
    font-family: LufgaRegular;
    margin-bottom: 20px;
}
.mobileDetected p {
    text-align: center;
    font-family: LufgaLight;
}
